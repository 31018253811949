/**
 * useSearch Composable
 *
 * Purpose:
 * This composable provides functionality to search for courses and bundles. It handles debounced
 * search to prevent excessive API calls and manages the state of search results, loading, and errors.
 * It also offers the ability to scroll to the search section of the page.
 *
 * Returns:
 * - courses: A ref holding an array of courses from search results.
 * - bundles: A ref holding an array of bundles from search results.
 * - searchQuery: A ref holding the current search query.
 * - currentSearchTerm: A ref holding the last searched term.
 * - isLoading: A ref indicating if the search is currently loading.
 * - isError: A ref indicating if there was an error in the search.
 * - searchDebounced: A function to debounce the search.
 * - search: A function to perform the actual search.
 * - scrollToSearch: A function to scroll the page to the search section.
 * - hasResults: A computed property indicating if the search returned results.
 *
 * Usage Example:
 * ```javascript
 * const { searchDebounced, courses, isLoading } = useSearch();
 * ```
 */
import type { components } from '~/interfaces/api/api'

export default function useSearch() {
  type Course = components['schemas']['Course']
  type CourseBundle = components['schemas']['CourseBundle']

  const { $api, $segment } = useNuxtApp()
  const courses = ref([]) as Ref<Course[]>
  const bundles = ref([]) as Ref<CourseBundle[]>
  const currentSearchTerm = ref('')
  const searchQuery = ref('')
  const isLoading = ref(false)
  const isError = ref(false)
  const timerId = ref(0)

  // Debounce the search to avoid frequent API calls
  function searchDebounced() {
    clearTimeout(timerId.value)
    // @ts-expect-error: TODO: Fix this
    timerId.value = setTimeout(() => {
      search()
    }, 300)
  }

  // Actual search function
  async function search() {
    // Avoid searching for very short queries
    if (searchQuery.value.length <= 1) { return }

    isLoading.value = true
    try {
      // Make the API call
      const res = await $api.search.postSearch({ query: searchQuery.value })

      // Update refs with search results
      if (res.courses) { courses.value = res.courses }

      if (res.course_bundles) {
        // @ts-expect-error - The API response type is not fully typed
        bundles.value = res.course_bundles
      }

      currentSearchTerm.value = searchQuery.value

      // Log the search to analytics (if $segment is available)
      $segment?.track('Product Searched', {
        query: searchQuery.value,
      }, {
        integrations: {
          'Facebook Pixel': false,
        },
      })
    }
    catch (error) {
      isError.value = true
    }
    finally {
      isLoading.value = false
    }
  }

  // Function to smoothly scroll the page to the search section
  function scrollToSearch() {
    const element = document.getElementById('search')
    if (element !== null) {
      element.scrollIntoView({
        behavior: 'smooth',
      })
    }
  }

  // Check if the search has any results
  const hasResults = computed(() => courses.value.length > 0 || bundles.value.length > 0)

  return {
    courses,
    bundles,
    searchQuery,
    currentSearchTerm,
    isLoading,
    isError,
    searchDebounced,
    search,
    scrollToSearch,
    hasResults,
  }
}
